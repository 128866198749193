const checkPermissions = (userRole, userCategory, allowedPermissions) => {
   if (!userRole || !userCategory) return false;
   if (!allowedPermissions) return true;

   if(userRole == 1) return true;

   for (let i = 0; i < allowedPermissions.length; i++) {
      if (allowedPermissions[i][0] == userRole && (allowedPermissions[i][1] == userCategory || allowedPermissions[i][1] == '*')) {
         return true;
      }
   }

   return false;
};

export default checkPermissions;
