import axios from "axios";
import useAuthStore from "@/stores/auth";
import * as Sentry from "@sentry/vue";

export const authClient = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  withCredentials: true, // required to handle the CSRF token
});

/*
 * Add a response interceptor
 */
authClient.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (
      error.response &&
      [401, 419].includes(error.response.status) &&
      useAuthStore.fetchAuthUser() &&
      !useAuthStore.guest()
    ) {
      console.log('no estas logueado')
      useAuthStore.logout();
    }
    return Promise.reject(error);
  }
);

export default {
  async login(payload) {
    await authClient.get("sanctum/csrf-cookie")
    return authClient.post("api/v1/login", payload);
  },
  logout() {
    return authClient.post("api/v1/logout");
  },
  async forgotPassword(payload) {
    await authClient.get("sanctum/csrf-cookie");
    return authClient.post("api/v1/forgot-password", payload);
  },
  async getAuthUser() {
    try {
      const response = await authClient.get("api/v1/user");
      const user = response.data.success;

      // Set the user information in Sentry
      Sentry.setUser({
        id: user.id,            // Add user id
        email: user.email,      // Add user email
      });

      return response;
    } catch (error) {
      console.error(error);
      throw error; // Rethrow the error if necessary
    }
  },
  async resetPassword(payload) {
    await authClient.get("sanctum/csrf-cookie");
    return authClient.post("reset-password", payload);
  },
  updatePassword(payload) {
    return authClient.put("user/password", payload);
  },
  async registerUser(payload) {
    await authClient.get("sanctum/csrf-cookie");
    return authClient.post("register", payload);
  },
  sendVerification(payload) {
    return authClient.post("email/verification-notification", payload);
  },
  updateUser(payload) {
    return authClient.put("user/profile-information", payload);
  },
};
