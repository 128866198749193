import { defineStore } from "pinia";
import { getError } from "@/includes/helpers";

import router from "@/router";
import AuthService from "@/services/AuthService";


export default defineStore("auth", {
  state:() => ({
    user: null,
    role: null,
    category: null,
    organization: null,
    isLoading: false,
    isLoggedIn: false, 
    error: null
  }),
  actions: {
    async logout() {
        await AuthService.logout()
          .then((response) => {
            console.log(response)
            this.user = null;
            this.setGuest("isGuest");
              router.push({ path: "/login"})
            
        })
        .catch((error) => {
            this.error = getError(error);
        });
    },
    async fetchAuthUser() {
      this.isLoading = !this.isLoading
      await AuthService.getAuthUser()
        .then(response => {
          this.user = response.data.success;
          this.isLoggedIn = true;
          this.role = this.user.role_id
          this.category = this.user.category_id
          this.organization = this.user.organization_id
          this.isLoading = !this.isLoading;
        })
        .catch(error => {
          console.log(error);
          this.error = getError(error)
          this.isLoggedIn = false;
          this.user = null;
          this.isLoading = !this.isLoading;
        })
    },
    async auth() {
      await AuthService.getAuthUser()
        .then(response => {
          const user = response.data.success;
          this.role = user.role_id
          this.category = user.category_id
          this.organization = user.organization_id
        })
        .catch(error => {
          console.log(error);
        })
    },
    setGuest(value) {
        window.localStorage.setItem("guest", value);
    },
  }
});
