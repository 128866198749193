import moment from "moment";

export const usersMap = (users) => {

  const keys_to_delete = ['area', 'area_id', 'category_id', 'channel', 'created_at', 'deleted_at', 'email_verified', 'manager_id',
    'organization_id', 'provider_id', 'region_code', 'role_id', 'two_factor_recovery_codes', 'two_factor_secret', 'updated_at'];

  users.forEach(user => {
    user.name_surname = user.name + user.surname;
    user.category = user.category && user.category.name ? user.category.name : '---';
    user.role = user.role  && user.role.name ? user.role.name : '---';
    user.group = user.area && user.area.name ? user.area.name : '---';
    user.last_update =  moment(user.updated_at).format("DD/MM/YY");
    user.team = user.team == 0 ? "0" : user.team;

    keys_to_delete.forEach(key => {
      delete user[key];
    })
  })

  return users;
}