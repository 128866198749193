<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";

import AuthService from "@/services/AuthService";
import MintyButton from "@/components/bookingsView/MintyButton.vue";
import MintySmoothHeight from "@/components/bookingsView/MintySmoothHeight.vue";

import mainLogo from "../../assets/images/main-logo.png";


import useAuthStore from "@/stores/auth";
import useUserStore from "@/stores/users";
import router from "@/router";

const authStore = useAuthStore();
const usersStore = useUserStore();

const previousRoute = ref(null);

const mode = ref('login');

//This is for the regular login
const authUser = ref({
  email: '',
  password: '',
  remember: 0
});
const loginError = ref(false);
const loadingLogin = ref(false);

//This is for resetting the password
const resetEmail = ref('');
const resetSuccess = ref(false);
const resetError = ref(false);
const resetMessage = ref('');
const loadingReset = ref(false);

const changeMode = (newMode) => {
  resetError.value = false;
  resetSuccess.value = false;
  loginError.value = false;

  mode.value = newMode;
}

const getDefaultRoute = () => {
  const role = authStore.role;
  const category = authStore.category;

  let result = "";

  const redirectFrom = localStorage.getItem('redirectFrom');

  if(role == 7 && category == 4) {
    result = "/current-bookings";
  }
  else {
    if(!redirectFrom || (redirectFrom && ['/','forgot-password','login'].includes(redirectFrom))) {
      result = "/checkins";
    }
    else {
      result = redirectFrom;
    }
  }

  return result;
}

const login = async() =>{
  try {
    loginError.value = false;
    loadingLogin.value = true;

    const response = await AuthService.login(authUser.value);

    if(response.status == 200) {
      await authStore.fetchAuthUser();
      usersStore.fetchCategories();
      usersStore.fetchRoles();

      router.push({ path: getDefaultRoute() });
    }
  } catch (error) {
    console.error(error);
    loginError.value = true;
  }

  loadingLogin.value = false;
}

const resetPassword = async () => {
  try {
    resetError.value = false;
    resetSuccess.value = false;
    loadingReset.value = true;
    resetMessage.value = "";

    const form = {
      email: resetEmail.value
    };

    const response = await AuthService.forgotPassword(form);
    const data = response.data.data;

    if(data == 'sent') {
      resetSuccess.value = true;
    }
    else if(data == 'invalid_user') {
      resetError.value = true;
      resetMessage.value = 'reset-error-invalid-email';
    }
    else if(data == 'too_many_attempts') {
      resetError.value = true;
      resetMessage.value = 'reset-error-too-many-attempts';
    }
    else {
      resetError.value = true;
      resetMessage.value = 'reset-error-generic';
    }
  }
  catch (error) {
    console.error(error);
    resetError.value = true;
    resetMessage.value = 'reset-error-generic';
  }

  loadingReset.value = false;
}

onMounted(() => {
  document.addEventListener('keydown', onEnterPress);
});

onBeforeUnmount(() => {
  document.removeEventListener('keydown', onEnterPress);
});

const onEnterPress = (e) => {
  let currentUrl = window.location.href;
  if(currentUrl.includes('login') == false) return;

  if(e.key == 'Enter') {
    if(mode.value == 'login') {
      login();
    }
    else if(mode.value == 'reset') {
      resetPassword();
    }
  }
}

</script>

<template>

  <div class="h-full w-full flex flex-col gap-6 md:gap-12 justify-center items-center">

    <div class="w-full h-content flex justify-center items-center">
      <img :src="mainLogo" alt="" class="h-32 w-32">
    </div>


    <div class="relative bg-gray-200 w-[90%] md:w-[70%] xl:w-[60%] 2xl:w-[50%] rounded-2xl flex flex-col justify-center items-center" v-auto-animate>

      <!-- Login -->

      <div
        v-if="mode == 'login'"
        class="custom-form"
      >
        <div class="w-full flex flex-col mb-3 border-2 border-blue-active rounded-xl px-3 h-12 item">
          <label for="email" id="email" class="w-fit bg-gray-200 -mt-3 px-2 text-blue-active">{{ $t('global.email') }}</label>
          <input type="text" class="bg-gray-200 border-0 outline-0 h-6 w-full px-2" v-model="authUser.email">
        </div>

        <div class="w-full flex flex-col mt-3 mb-5 border-2 border-blue-active rounded-xl px-3 h-12">
          <label for="password" id="password" class="w-fit bg-gray-200 -mt-3 px-2 text-blue-active">{{ $t('global.password') }}</label>
          <input type="password" class="bg-gray-200 border-0 outline-0 h-6 w-full px-2" v-model="authUser.password">
        </div>

        <div class="w-full text-blue-active text-sm mb-5">
          <input type="checkbox" v-model="authUser.remember" name="remind" id="remind" class="form-radio h-5 w-5 text-blue-active 
            checked:text-blue-active rounded-md ring-0 focus:ring-0  border-2 hover:bg-blue-cta 
            checked:border-blue-cta checked:border-2 focus:text-blue-cta" >
          <label for="remind" class="ml-3">{{ $t('global.remember') }}</label>
        </div>

        <MintySmoothHeight :showIf="loginError">
          <p class="w-full border-2 border-red-700 bg-red-200 text-red-800 text-center font-semibold p-3 rounded-lg mb-8 bg-opacity-50" >
          {{ $t('global.unauthorised') }}
          </p>
        </MintySmoothHeight>

        <div class="flex flex-nowrap justify-center">
          <MintyButton
            :isLoading="loadingLogin"
            @onClick="login"
          >
            {{ $t('login.submit') }}
          </MintyButton>
        </div>

        <p
          class="absolute bottom-0 right-0 m-3 mx-4 text-blue-active text-sm hover:cursor-pointer hover:opacity-70 font-semibold"
          @click="changeMode('reset')"
        >
          {{ $t('global.forgot') }}
        </p>
      </div>


      <!-- Reset Password -->
      <div
        v-else-if="mode == 'reset'"
        class="custom-form"
      >
        <h1 class="text-xl mb-4 font-bold text-gray-700">{{ $t('global.reset') }}</h1>


        <div class="w-full flex flex-col border-2 border-blue-active rounded-xl px-3 h-12 mb-8">
          <label for="email" id="email" class="w-fit bg-gray-200 -mt-3 px-2 text-blue-active">{{ $t('global.email') }}</label>
          <input type="text" class="bg-gray-200 border-0 outline-0 h-6 w-full px-2" v-model="resetEmail">
        </div>


        <MintySmoothHeight :showIf="resetError">
          <p class="w-full border-2 border-red-700 bg-red-200 text-red-800 text-center font-semibold p-3 rounded-lg mb-8 bg-opacity-50" >
          {{ $t('login.' + resetMessage) }}
          </p>
        </MintySmoothHeight>

        <MintyButton
          :isLoading="loadingReset"
          @onClick="resetPassword"
        >
          {{ $t('global.reset_button') }}
        </MintyButton>

        <MintySmoothHeight :showIf="resetSuccess">
          <p class="w-full border-2 border-green-700 bg-green-200 text-green-800 text-center font-semibold p-3 rounded-lg mt-8 bg-opacity-50" >
            {{ $t('global.reset_success') }}
          </p>
        </MintySmoothHeight>

        <p
          class="absolute bottom-0 right-0 m-3 mx-4 text-blue-active text-sm hover:cursor-pointer hover:opacity-70 font-semibold"
          @click="changeMode('login')"
        >
          {{ $t('login.back-to-login') }}
        </p>

      </div>

    </div>
  </div>

</template>

<style lang="scss" scoped>
.custom-form {
  @apply w-full h-full flex flex-col gap-2 justify-center items-center py-12 pb-20 md:py-24 md:pb-[90px] px-6 md:px-12;
}

  input[type=text] {
    outline: 0;
    border: 0px;
    @apply font-semibold text-gray-800;
  }
  input:focus {
    box-shadow:none !important;
  }
</style>
