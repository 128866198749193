<script setup>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { ref, computed, useSlots, getCurrentInstance } from "vue";
import { useRoute, useRouter } from "vue-router";
import checkPermissions from '@/includes/check-permissions.js';
import authStore from "@/stores/auth";

const route = useRoute();
const router = useRouter();

const env = import.meta.env.VITE_APP_ENV;

const mainUrl = import.meta.env.VITE_URL_VUE;
const coreUrl = import.meta.env.VITE_URL_OLD_VUE;

import LinkWrapper from "./LinkWrapper.vue";

const props = defineProps({
  //This is for font-awesome-icons
  icon: { default: null },

  //This is for images saved in the assets folder
  image: { default: null },

  //Array of combo role/category like [ [1,3], [2,3], [3,1], ... ]
  //Can also use * to allow all of that category/role like [ [1, *], [*, 2], ... ]
  allow: {
    default: null,
    required: false,
  },

  url: {
    type: String,
    default: "#",
  },

  envRedirect: {
    type: [Array, String],
    default: () => [
      { env: 'prod', sendTo: 'core', },
      { env: 'dev', sendTo: 'main', },
      { env: 'local', sendTo: 'main', },
    ],
  },

  showIn: {
    type: [Array, String],
    default: () => ['prod', 'dev', 'local'],
  },

  title: {
    type: String,
    default: "",
  },
});

const open = ref(false);

const handleClick = () => {
  if(hasChildren.value) {
    open.value = !open.value;
  }
}

const getRedirectValue = computed(() => {
  //The redirect value either starts with:
  //b: button dropdown, m: main, c: core

  if(!props.url || props.url == "#") return 'b';
  let returnUrl = '';

  //Current redirect tells us if this button should redirect to core or main
  //depending on the environment
  let currentRedirect = null;
  if(props.envRedirect instanceof Array)
    currentRedirect = props.envRedirect.find(item => item.env == env)?.sendTo;
  else
    currentRedirect = props.envRedirect;

  let baseUrl = currentRedirect == 'core' ? coreUrl : '';

  if(currentRedirect == 'core') returnUrl += 'c';
  else if(currentRedirect == 'main') returnUrl += 'm';

  return returnUrl + baseUrl + props.url;
});

const imageUrl = computed(() => {
  if (props.image) {
    return new URL(`../../assets/images/sidebar/${props.image}.png`, import.meta.url).href;
  }

  return null;
});

const hasChildren = computed(() => {
  return useSlots().default;
});

const isChildren = computed(() => {
  const parent = getCurrentInstance().parent?.type?.__name;
  return parent == "MenuItemV2";
});

const isAllowed = computed(() => {
  if (!props.allow) return true;

  const role = authStore()?.role;
  const category = authStore()?.category;

  return checkPermissions(role, category, props.allow);
});

const isCurrentlyInSection = computed(() => {
  if(!hasChildren.value)
    return route.fullPath == props.url;
  else {
    const instance = getCurrentInstance();
    const children = instance?.slots.default?.() || [];

    let found = false;
    children.forEach((vnode) => {
      if (vnode.type?.props && vnode.props) {
        if (vnode.props.url === route.fullPath) {
          found = true;
        }
      }
    });
    return found;
  }
});

</script>

<template>
  <div
    v-if="isAllowed && showIn.includes(env)"
    class="w-full text-white max-w-[350px]"
  >
    <LinkWrapper
      :class="[
        'w-full flex items-center gap-4 font-bold text-[95%] px-3 rounded-lg hover:text-blue-cta cursor-pointer transition-all duration-100 active:scale-90',
        isCurrentlyInSection && !isChildren ? 'bg-blue-menu' : '',
        !isChildren ? 'hover:bg-blue-menu-secondary py-2' : 'py-[8px]',
      ]"

      :to="getRedirectValue"
      @toggle="handleClick"
    >

      <div class="w-5 h-5" v-if="icon || image">
        <font-awesome-icon v-if="icon" :icon="icon" class="w-full h-full" />
        <img v-else-if="image" :src="imageUrl" class="w-full h-full" />
      </div>

      <span
        :class="[
          'leading-none text-left select-none',
          isChildren ? 'text-[88%] ml-3' : '',
        ]"
      >
        {{ $t('menu.' + title) }}
      </span>

      <span
        v-if="hasChildren"
        class="h-3 aspect-square ml-auto flex items-center"
      >
        <font-awesome-icon
          :icon="'chevron-left'"
          class="w-full h-full transition-all duration-300"
          :class="[
            open ? 'transform -rotate-90' : '',
          ]"
        />
      </span>
    </LinkWrapper>

    <div
      class="w-full ml-5 border-l-2 border-white transition-all duration-300 overflow-hidden border-opacity-50"
      :class="[
        open ? 'max-h-[300px] my-3 opacity-100' : 'max-h-0 opacity-0'
      ]"
    >
      <slot />
    </div>

  </div>
</template>
