<template>
  <div class="custom-scrollbar bg-blue-template w-full md:min-h-[90svh] lg:flex lg:flex-row">
    <!-- <side-bar class="relative shadow-2xl" v-if="route.name != '404' && route.name != 'login' && route.name != 'forgot-password'"></side-bar> -->
    <SideBarV2 v-if="route.name != '404' && route.name != 'login' && route.name != 'forgot-password'" />
    <div
      class="overflow-x-hidden content"
      :class="
        route.name != '404' && route.name != 'login' && route.name != 'forgot-password' ? 'mt-20 lg:mt-0 lg:ml-[220px]' : ''
      "
    ></div>
    <router-view />
  </div>
</template>

<script setup>
// import SideBar from "./views/navbar/SideBar.vue";
import SideBarV2 from "./views/navbar/SideBarV2.vue";
import { useRoute } from "vue-router";

const route = useRoute();
</script>

<style lang="scss">
body::-webkit-scrollbar {
  @apply w-2 bg-transparent;
}

body::-webkit-scrollbar-thumb {
  @apply transition duration-200 bg-blue-cta rounded-full;
}

:root {
  background-color: #dadef7;
}

#sentry-feedback {
  --button-text-color: #ffffff; /* Change to your desired text color */
  --accent-background: #5165D7; /* Change to your desired text color */
  --outline: #5165D7; /* Change to your desired text color */
  --font-family: "Quicksand", sans-serif; /* Change to your desired font */
}
</style>
