<script setup>
import { computed } from "vue";

const props = defineProps({
  to: {
    required: true,
  },

  class: {
    type: [String, Array],
    default: "",
  },
});

const type = computed(() => {
  return props.to[0]; //b for button, c core, m main
});

const url = computed(() => {
  if (type == "b") return "";
  else
    return props.to.slice(1);
});

const emit = defineEmits(["toggle"]);

</script>

<template>

  <!-- Router link if the route goes to main -->
  <RouterLink
    v-if="type == 'm'"
    :class="class"
    :to="url"
  >
    <slot />
  </RouterLink>

  <!-- Regular link if it goes to core -->
  <a
    v-else-if="type == 'c'"
    :class="class"
    :href="url"
  >
    <slot />
  </a>

  <!-- Just a regular div if its a dropdown -->
  <div
    v-else
    :class="class"
    @click="emit('toggle')"
  >
    <slot />
  </div>

</template>
