<script setup>
import LoginForm from '@/components/login/LoginForm.vue';
import LoginFooter from '@/components/login/LoginFooter.vue';

import imgBackground from '../../assets/images/bg-header2.jpg'

document.title = 'Login | MP';
</script>

<template>
  <div
    class="imgBack w-screen h-screen bg-blue-active flex flex-row relative"
    :style="{ backgroundImage: `url(${imgBackground})` }"
  >

    <div class="w-full lg:w-1/2 ml-auto bg-blue-active bg-opacity-95 rounded-tl-full">
      <LoginForm />
    </div>

    <LoginFooter />
  </div>
</template>
