<script setup>
  import mainLogo from "@/assets/images/main-logo.png";
  import imgBackground from '@/assets/images/bg-header2.jpg';
  import xLogo from "../../assets/images/icon-x.svg";
  import facebookLogo from "../../assets/images/icon-facebook.svg";
  import instagramLogo from "../../assets/images/icon-instagram.svg";
  import linkedinLogo from "../../assets/images/icon-linkedin.svg";
  import { ButtonCTA } from "myplazze-components";
  import router from "@/router";
  import { ref } from "vue";
  import AuthService from "@/services/AuthService";

  import LoginFooter from '@/components/login/LoginFooter.vue';

  const email = ref('');
  const success = ref(false);
  const reset_error = ref(false);

  const resetPassword = async () => {
    await AuthService.forgotPassword(email)
    .then(response => {
      if(response.status == '200'){
        success.value = !success.value
      }
    })
    .catch(error => {
      reset_error.value = !reset_error.value
    })
  }
</script>

<template>
  <div class="w-screen h-screen bg-blue-active flex flex-col relative items-center justify-center gap-y-10"
    :style="{ backgroundImage: `url(${imgBackground})` }"
  >

    <LoginFooter />

    <div class="w-full h-[90%] flex flex-col items-center justify-center">
      <img :src="mainLogo" alt="" class="h-32 w-32 mb-8">
      <div v-if="!success" class="w-[90%] md:w-[80%] lg:w-[50%] h-[50%] rounded-xl bg-blue-active opacity-80 flex flex-col items-center justify-around font-bold text-gray-200 p-8 ">
        <h1 class="text-4xl md:text-5xl lg:text-6xl">{{ $t('global.reset') }}</h1>
        <p class="text-lg">{{ $t('global.reset_msg') }}</p>
        <input type="text" class="w-[80%] sm:w-[60%] lg:w-[40%] bg-gray-200 rounded-md shadow-lg" v-model="email">
        <div v-if="reset_error" class="text-red-delete">
          {{ $t('global.error_reset') }}
        </div>
        <button-CTA @click.prevent="resetPassword">{{ $t('global.reset_button') }}</button-CTA>
      </div>    
      <div v-if="success" class="w-[90%] md:w-[80%] lg:w-[50%] h-[50%] rounded-xl bg-blue-active opacity-80 flex flex-col items-center justify-around font-bold text-gray-200 p-8 ">
        <p class="text-lg">{{ $t('global.reset_success') }}</p>
        <button-CTA @click.prevent="router.push({name: 'login'})">{{ $t('global.login') }}</button-CTA>
      </div>
    </div>

  </div>
</template>
